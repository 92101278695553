<template>
  <el-dialog
    :visible.sync="show"
    width="40%"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div slot="title" class="header-title">
      <el-row :gutter="5">
        <el-col :span="24">
          <span class="title-name">{{ info.title }}</span>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col :span="24">
        <el-form ref="ruleForm" :model="form" label-width="110px" class="allForm">
          <el-form-item label="字典名称" :required="true">
            <el-input v-model="form.dictName" class="formInput" maxlength="25" />
          </el-form-item>
          <el-form-item label="字典编码" required>
            <el-input v-model="form.dictCode" class="formInput" maxlength="25" :disabled="form.isDefault===0&&info.addOr==='upd'" />
          </el-form-item>
          <el-form-item label="是否系统数据" :required="true">
            <el-select v-model="form.isDefault" class="formInput w-100" :disabled="form.isDefault===0&&info.addOr==='upd'">
              <el-option :value="0" label="是" />
              <el-option :value="1" label="否" />
            </el-select>
          </el-form-item>
          <!--          <el-form-item label="父编码">-->
          <!--            <el-input v-model="form.parentCode" class="formInput" maxlength="25" />-->
          <!--          </el-form-item>-->
          <el-form-item label="备注">
            <el-input v-model="form.remarks" class="formInput" />
          </el-form-item>
          <el-form-item label="排序">
            <el-input v-model.number="form.sort" class="formInput" />
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer" style="text-align: center;">
      <el-button type="primary" @click="commit">提交</el-button>
      <el-button type="info" @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { dict } from '@/api'
export default {
  name: 'Operate',
  props: {
    // 打开dialog的
    dialog: {
      type: Boolean, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: false // 这样可以指定默认的值
    },
    info: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {} // 这样可以指定默认的值
    },
    row: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {} // 这样可以指定默认的值
    }
  },
  data() {
    return {
      show: this.dialog,
      form: {
        dictCode: '',
        dictName: '',
        isDefault: 0,
        parentCode: '',
        remarks: '',
        sort: 0
      },
      rules: {
        dictCode: [
          { required: true, message: '请输入字典编码', trigger: 'blur' },
          { pattern: '', message: '不符合格式！！！' }
        ],
        dictName: [
          { required: true, message: '请输入字典名称', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.upd()
  },
  methods: {
    upd() {
      if (this.info.addOr === 'upd') {
        this.form = this.row
      }
    },
    // 提交添加
    commit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.info.addOr === 'addRoot') {
            this.$axiosReq(dict, null, this.form, 'post').then(res => {
              this.$message.success(res.msg)
              this.close()
              this.$emit('get-list')
            })
          } else if (this.info.addOr === 'addSon') {
            this.form.parentCode = this.row.dictCode
            this.form.parentId = this.row.id
            this.form.children=null
            this.$axiosReq(dict, null, this.form, 'post').then(res => {
              this.$message.success(res.msg)
              this.close()
              this.$emit('get-list')
            })
          } else {
            this.form.children=null
            this.$axiosReq(dict + this.row.id, null, this.form, 'put').then(res => {
              this.$message.success(res.msg)
              this.close()
              this.$emit('get-list')
            })
          }
        } else {
          return false
        }
      })
    },
    // 关闭模态框
    close() {
      this.show = false
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>

</style>
